import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import CoachMap from "./components/CoachMap";
import CoachListing from "./components/CoachListing/CoachListing";
import CoachFilterWrapper from "./components/CoachFilter/FilterWrapper";
import CoachDetailWrapper from "./components/CoachDetail/CoachDetailWrapper";

function App() {
  const [filterState, setFilterState] = useState({});
  const [activeCoach, setActiveCoach] = useState("");

  const coachFilterHandler = (f: object) => {
    setFilterState(f);
  };

  const SelectedCoach = (a: string) => {
    if (a) {
      setActiveCoach(a);
    } else {
      setActiveCoach("");
    }
  };

  return (
    <div className="App">
      <CoachMap />
      <CoachFilterWrapper onFilterChange={coachFilterHandler} />
      {activeCoach === "" ? (
        <CoachListing filterValue={filterState} selected={SelectedCoach} />
      ) : (
        <CoachDetailWrapper active={activeCoach} selected={SelectedCoach} />
      )}
    </div>
  );
}

export default App;
