import { Select } from "antd";

const FilterLanguage = (props: any) => {
  const cl = [
    ...new Set(
      props.data.flatMap((coach: { languages: string[] }) => coach.languages)
    ),
  ];

  const filteroptions = cl.map((language: any) => {
    return { value: language, label: language };
  });

  const handleSelect = (event: string) => {
    props.onFilter(event, "language");
  };

  return (
    <Select
      options={filteroptions}
      onChange={handleSelect}
      showSearch
      allowClear
      placeholder="Sprache"
      style={{ width: "100%" }}
      size="large"
    />
  );
};

export default FilterLanguage;
