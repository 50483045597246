import { Select } from "antd";

const FilterCountry = (props: any) => {

  const cl = [
    ...new Set(props.data.map((coach: any) => coach.location.country)),
  ];

  const filteroptions = cl.map((location: any) => {
    return { value: location, label: location };
  });

  const handleSelect = (event: string) => {
    props.onFilter(event, "country");
  };

  return (
    <Select
      options={filteroptions}
      onChange={handleSelect}
      size="large"
      allowClear
      showSearch
      placeholder="Länderauswahl"
      style={{ width: "100%" }}
    />
  );
};

export default FilterCountry;
