const coachData = [{
  "location": { "city": "Kairo", "country": "Ägypten" },
  "profession": ["Leadership", "Executive"],
  "languages": ["AR", "DE", "FR", "EN"],
  "_id": "60b06c6345eff66baeadfb75",
  "name": "Wahid Sharaf",
  "degree": "",
  "email": "wahid.sharaf@promovetm.com",
  "experience_yrs": 15,
  "shortbio": "Executive & Leadership coaching, focusing on transformational, cross-cultural, conflict & mediation coaching. Follows a holistic, systemic approach incorporating where needed somatic coaching elements inspired by the Japanese martial art Aikido",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb75.jpg"
}, {
  "location": { "city": "Paris", "country": "Frankreich" },
  "profession": ["Executive", "Team"],
  "languages": ["FR"],
  "_id": "60b06c6345eff66baeadfb76",
  "name": "Noëllyne Bernard",
  "degree": "",
  "email": "noellyne.bernard@ambroiseconseil.com",
  "experience_yrs": 15,
  "shortbio": "For over 15 years, Noëllyne has been supporting the creators of innovative companies, on strategic, financial and human subjects. She has chosen the world of startups because each innovative project is unique in terms of creativity and rhythm. Sectors: financial services, insurances, IT and consulting. Jury member of TOP JOB, and long-standing board member of the initiative “Wege zur Selbst-GmbH e.V.”",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb76.jpg"
}, {
  "location": { "city": "Bologna", "country": "Italien" },
  "profession": ["Change Management", "Leadership"],
  "languages": ["IT", "EN"],
  "_id": "60b06c6345eff66baeadfb77",
  "name": "Roberta Pagliarulo",
  "degree": "",
  "email": "",
  "experience_yrs": null,
  "shortbio": "Responsible for recruiting and training processes of several companies, she also works as a freelance soft skills trainer (leadership, negotiation, problem solving, communication, train the trainer, public speaking, team working some). She took part in many Coaching and Mentoring processes within Corporate and Business areas in a wide range of Companies and also worked as a supervisor and trainer in certification coaching courses. Some companies where she collaborated as a coach and trainer: Ferrari, Porsche Engineering, Enel, Acea, FPT, Bosch, H3G, Dompè Corporate, Poste Italiane, De Cecco, Telecom.",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb77.jpg"
}, {
  "location": { "city": "Hamburg", "country": "Deutschland" },
  "profession": ["Leadership"],
  "languages": ["DE", "EN"],
  "_id": "60b06c6345eff66baeadfb79",
  "name": "Uwe Weiss",
  "degree": "",
  "email": "",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Athen", "country": "Griechenland" },
  "profession": ["Executive", "Organisational Development"],
  "languages": ["EN", "EL"],
  "_id": "60b06c6345eff66baeadfb7a",
  "name": "Irini Nikolaidou",
  "degree": "",
  "email": "inikolaidou@movingminds.gr",
  "experience_yrs": 25,
  "shortbio": "More than 25 years of working experience and professional career in managerial positions of large companies such as Administrative, Human Resources Management and Communications.",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb7a.jpg"
}, {
  "location": { "city": "Oslo", "country": "Norwegen" },
  "profession": ["Leadership", "Executive"],
  "languages": ["EN", "FR", "NO"],
  "_id": "60b06c6345eff66baeadfb7b",
  "name": "Mickael Melaye",
  "degree": "",
  "email": "mickael.melaye@promovetm.com",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Oslo", "country": "Norwegen" },
  "profession": ["Executive", "Change Management"],
  "languages": ["EN"],
  "_id": "60b06c6345eff66baeadfb7c",
  "name": "Jan Solvberg",
  "degree": "",
  "email": "jan.solvberg@promovetm.com",
  "experience_yrs": 20,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Nur Sultan", "country": "Kasachstan" },
  "profession": ["Leadership", "Team"],
  "languages": ["EN", "KZ", "RU", "AR", "DE", "FR"],
  "_id": "60b06c6345eff66baeadfb7d",
  "name": "Maria Kovtsur",
  "degree": "",
  "email": "",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Nur Sultan", "country": "Kasachstan" },
  "profession": ["Leadership", "Executive"],
  "languages": ["EN", "KZ", "RU"],
  "_id": "60b06c6345eff66baeadfb7e",
  "name": "Bakhytzhan Sanbayev",
  "degree": "",
  "email": "",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Panama City", "country": "Panama" },
  "profession": ["Executive", "Organisational Development"],
  "languages": ["ES", "EN", "PT"],
  "_id": "60b06c6345eff66baeadfb7f",
  "name": "Morelba Rios Dayaram",
  "degree": "",
  "email": "morelba.rios@gmail.com",
  "experience_yrs": null,
  "shortbio": "Long track record in C-Level, Executive and Team Coaching and in Implementing Coaching as part of a Company Culture. Creator of a Coaching Methodology “Coaching para Todos”. Co-author of a coaching book “Coaching um estilo de vida”. Longstanding Experience as Corporate Manager, Process consultant, Project and Change Management, Resources Integrator due to Mergers & Acquisitions. Leader of multicultural and multidisciplinary teams. Has worked internationally in the following sectors: Oil & Energy, Telecommunications, Insurance, Bank, Services, Family, Education and Manufacturing sectors.",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb7f.jpg"
}, {
  "location": { "city": "Moskau", "country": "Russland" },
  "profession": ["Organisational Development", "Team"],
  "languages": ["SV", "EN", "RU"],
  "_id": "60b06c6345eff66baeadfb80",
  "name": "Anastasia Nekrasova",
  "degree": "",
  "email": "anastasia@intelligentmindsets.com",
  "experience_yrs": null,
  "shortbio": "Personal and Organisational transformation integral consulting; vertical leadership development / mindset transformation. Certi-fied user of cultural intelligence (CQ) assessment (with Cultural Intelligence Center, MI, USA, www.culturalq.com); CQ coach.",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb80.jpg"
}, {
  "location": { "city": "Capetown", "country": "Südafrika" },
  "profession": ["Executive"],
  "languages": ["EN", "DE", "AF"],
  "_id": "60b06c6345eff66baeadfb81",
  "name": "Les Weiss",
  "degree": "",
  "email": "",
  "experience_yrs": 18,
  "shortbio": "Executive and business leaders and the challenges these persons at all levels, face through both one-on-one and team coaching. Programmes vary in length and approach but are all focused on clearly described goals.",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb81.jpg"
}, {
  "location": { "city": "Seoul", "country": "Südkorea" },
  "profession": ["Executive"],
  "languages": ["EN", "KR"],
  "_id": "60b06c6345eff66baeadfb82",
  "name": "Jeong-Soo Choe",
  "degree": "",
  "email": "js.choe@mrikr.com",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "New York", "country": "USA" },
  "profession": ["Executive", "Team"],
  "languages": ["EN"],
  "_id": "60b06c6345eff66baeadfb83",
  "name": "Elizabeth Mccourt",
  "degree": "",
  "email": "elizabeth@mccourtleadership.com",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "New York ", "country": "USA" },
  "profession": ["Leadership", "Executive"],
  "languages": ["EN"],
  "_id": "60b06c6345eff66baeadfb84",
  "name": "Mark Schall",
  "degree": "",
  "email": "mark@markschallcoaching.com",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Paris", "country": "Frankreich" },
  "profession": ["Team", "Outplacement"],
  "languages": ["FR"],
  "_id": "60b06c6345eff66baeadfb85",
  "name": "Gersende Bado",
  "degree": "",
  "email": "gersende.bado@ambroiseconseil.com",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Paris", "country": "Frankreich" },
  "profession": ["Career", "Outplacement"],
  "languages": ["FR"],
  "_id": "60b06c6345eff66baeadfb86",
  "name": "Laurence Delannoy",
  "degree": "",
  "email": "laurence.delannoy@free.fr",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Paris", "country": "Frankreich" },
  "profession": ["Career", "Team"],
  "languages": ["FR"],
  "_id": "60b06c6345eff66baeadfb87",
  "name": "Patrice Couillet",
  "degree": "",
  "email": "patrice.couillet@gmail.com",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Paris", "country": "Frankreich" },
  "profession": ["Career", "Executive"],
  "languages": ["FR"],
  "_id": "60b06c6345eff66baeadfb88",
  "name": "Isabelle Weiss",
  "degree": "",
  "email": "isabelle.weiss@ataillehumaine.fr",
  "experience_yrs": null,
  "shortbio": "",
  "biography": ""
}, {
  "location": { "city": "Wien", "country": "Österreich" },
  "profession": [],
  "languages": ["DE", "EN", "FR"],
  "_id": "60b06c6345eff66baeadfb89",
  "name": "Michael Kres",
  "degree": "Dr. oec HSG",
  "email": "michael.kres@promovetm.com",
  "experience_yrs": null,
  "shortbio": "Longstanding management experience in the areas of aviation, telecom, services, education and professional development. Focus: Transformation of classical management companies into self-organizing companies. Organisational coaching. Executive and Leadership Coaching. Lecturing activity at universities and advanced education institutions. Broad range of publications on the subjects of «Demographic Shifts», «Employability» and «Courage and Management».",
  "biography": "",
  "imgUrl": "/coaches/60b06c6345eff66baeadfb89.jpg"
}]
export default coachData