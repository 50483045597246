const CoachLanguage = (props: any) => {
  const imgBase = "/languages/";
  const { languages } = props;

  return (
    <div className="coach-languages">
      {languages.map((lang: string, index: number) => (
        <img src={imgBase + lang + ".svg"} alt={lang} key={index} />
      ))}
    </div>
  );
};

export default CoachLanguage;
