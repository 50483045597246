import { Row, Col } from "antd";
import CoachItem from "./CoachItem";
import "./CoachListing.css";

import coachData from "./coachData";

const CoachListing = (props: any) => {
  const filter = props.filterValue;

  let filteredData = coachData;

  if (filter.name) {
    filteredData = filteredData.filter((coach) => coach.name === filter.name);
  }

  if (filter.country) {
    filteredData = filteredData.filter(
      (coach) => coach.location.country === filter.country
    );
  }

  if (filter.profession) {
    filteredData = filteredData.filter((coach) =>
      coach.profession.some((p) => p === filter.profession)
    );
  }

  if (filter.language) {
    filteredData = filteredData.filter((coach) =>
      coach.languages.some((l) => l === filter.language)
    );
  }

  const clickhandler = (e: any) => {
    props.selected(e.currentTarget.dataset.id);
  };

  return (
    <Row gutter={[24, 24]} className="coach-listing">
      {filteredData.map((coach) => (
        <Col xs={24} md={12} lg={8} xl={6} key={coach._id}>
          <div
            className="coach-wrap"
            data-id={coach._id}
            onClick={clickhandler}
          >
            <CoachItem
              name={coach.name}
              avatar={coach.imgUrl}
              degree={coach.degree}
              profession={coach.profession}
              location={coach.location}
              languages={coach.languages}
            />
          </div>
        </Col>
      ))}
    </Row>
    //
  );
};

export default CoachListing;
