import React from "react";
import { Row, Col, Avatar } from "antd";

import coachData from "../CoachListing/coachData";

import "./CoachDetail.css";

function CoachDetailWrapper(props: any) {
  // Filter Passed CoachID and return first Object
  const coachInfo = coachData.filter((obj) => obj._id === props.active)[0];
  const handleBack = () => {
    props.selected(0);
  };

  return (
    <>
      <div className="coach-detail">
        <button className="back-btn btn is--primary" onClick={handleBack}>
          Zurück zu den Coaches
        </button>
      </div>

      <Row className="coach-detail main">
        <Col
          span={6}
          style={{
            padding: "2rem 1rem",
            textAlign: "center",
            borderRight: "1px solid darkgrey",
          }}>
          <Avatar size={150} src={coachInfo.imgUrl}></Avatar>
          <div className="coach-name" style={{ marginTop: "2rem" }}>
            {coachInfo.name}
          </div>
          <div className="coach-degree">{coachInfo.degree}</div>
          <div className="coach-profession">
            {coachInfo.profession.join(", ")}
          </div>
          <div className="coach-location">{coachInfo.location.country}</div>
          <a
            href={
              "mailto:info@promovetm.com?subject=[Kontaktanfrage]%20" +
              encodeURIComponent(coachInfo.name)
            }
            className="coach-contact btn is--primary">
            Kontaktieren
          </a>
        </Col>
        <Col span={18} style={{ padding: "2rem 1rem" }}>
          <div className="short-bio">{coachInfo.shortbio}</div>
          <div className="long-bio">{coachInfo.biography}</div>
          {/* IF NO SHORT-BIO IS FOUND */}
          {coachInfo.shortbio === "" &&
            "Dieser Coach hat noch nichts zu erzählen..."}
        </Col>
      </Row>
    </>
  );
}

export default CoachDetailWrapper;
