import { Card } from "antd";

// CoachItem Imports
import CoachItemAvatar from "./ItemChildren/CoachItemAvatar";
import CoachLanguage from "./ItemChildren/CoachLanguage";

const CoachItem = (coach: any) => {

  const contactCoach = () => {
    const targetMail = "info@promovetm.com"
    const subject = "Coach Kontaktanfrage " + coach.name

    return `mailto:${targetMail}?subject=${encodeURIComponent(subject)}`
  }

  return (
    <Card
      className="coach-card"
      actions={[<a href={contactCoach()}>KONTAKTIEREN</a>]}
    >
      <div className="coach-card__body">
        <CoachItemAvatar name={coach.name} avatar={coach.avatar} />
        <div className="coach-name">{coach.name}</div>
        <div className="coach-degree">{coach.degree}</div>
        <div className="coach-profession">{coach.profession.join(", ")}</div>
        <div className="coach-location">{`${coach.location.city} ${coach.location.country}`}</div>
        <CoachLanguage languages={coach.languages} />
      </div>
    </Card>
  );
};

export default CoachItem;
