import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

const CoachMap = () => {
  const markerIcon = new Icon({
    iconUrl: "marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  return (
    <MapContainer
      center={[40, 0]}
      zoom={1.5}
      minZoom={1.5}
      worldCopyJump
      scrollWheelZoom={false}
      style={{ height: "500px" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[30.033, 31.233]} icon={markerIcon} />
      <Marker position={[48.864716, 2.349014]} icon={markerIcon} />
      <Marker position={[44.4989, 11.3275]} icon={markerIcon} />
      <Marker position={[50.737431, 7.098207]} icon={markerIcon} />
      <Marker position={[53.551086, 9.993682]} icon={markerIcon} />
      <Marker position={[37.98381, 23.727539]} icon={markerIcon} />
      <Marker position={[59.9133301, 10.7389701]} icon={markerIcon} />
      <Marker position={[47.2286086, 65.2093197]} icon={markerIcon} />
      <Marker position={[8.559559, -81.1308434]} icon={markerIcon} />
      <Marker position={[55.7504461, 37.6174943]} icon={markerIcon} />
      <Marker position={[-33.926759, 18.425768]} icon={markerIcon} />
      <Marker position={[37.547893, 126.941894]} icon={markerIcon} />
      <Marker position={[40.7127281, -74.0060152]} icon={markerIcon} />
      <Marker position={[47.3666667, 8.55]} icon={markerIcon} />
    </MapContainer>
  );
};

export default CoachMap;
