import { Avatar } from 'antd';

const CoachItemAvatar = (coach: any) => {
  return (
    <Avatar
      alt={coach.name}
      size={128}
      style={{ marginBottom: "2rem" }}
      src={coach.avatar ? coach.avatar : "/coaches/no-coach.jpg"}
    />
  );
};

export default CoachItemAvatar