import React from 'react';
import ReactDOM from 'react-dom';

// Style Imports
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import './index.css';

import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);