import { Col, Row } from "antd";
// import FilterCoach from "./FilterCoach";
import FilterCountry from "./FilterCountry";
import FilterProfession from "./FilterProfession";
import FilterLanguage from "./FilterLanguage";
import "./FilterWrapper.css";

import coachData from "../CoachListing/coachData";
import { useState, useEffect } from "react";

const CoachFilterWrapper = (props: any) => {
  const [filterState, setFilterState] = useState({
    name: "",
    country: "",
    profession: "",
    language: "",
  });

  const coachFilterHandler = (event: string, type: string) => {
    setFilterState({...filterState, [type]: event})
  };

  useEffect(() => {
    props.onFilterChange(filterState)
  })

  return (
    <div className="filter-wrapper">
      <Row gutter={24}>
        {/*<Col xs={24} md={12} lg={6}>*/}
        {/*  /!*<FilterCoach data={coachData} onFilter={coachFilterHandler} />*!/*/}
        {/*</Col>*/}
        <Col xs={24} md={8} lg={8}>
          <FilterCountry data={coachData} onFilter={coachFilterHandler} />
        </Col>
        <Col xs={24} md={8} lg={8}>
          <FilterProfession data={coachData} onFilter={coachFilterHandler} />
        </Col>
        <Col xs={24} md={8} lg={8}>
          <FilterLanguage data={coachData} onFilter={coachFilterHandler} />
        </Col>
      </Row>
    </div>
  );
};
export default CoachFilterWrapper;
